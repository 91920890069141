import { http } from '@/http/axios.js'
// 商品列表
export function getGoodsListAPI(params) {
  return http({
    url: '/admin/goods/page',
    method: 'get',
    params
  })
}
// 商品详情
export function getGoodsDetailAPI(id) {
  return http({
    url: '/admin/goods/detail',
    method: 'get',
    params: { id }
  })
}
// 删除商品
export function deleteGoodsAPI(id) {
  return http({
    url: '/admin/goods/delete',
    method: 'post',
    data: { id }
  })
}
// 新增商品
export function addGoodsAPI(data) {
  return http({
    url: '/admin/goods/add',
    method: 'post',
    data
  })
}
// 编辑商品
export function editGoodsAPI(data) {
  return http({
    url: '/admin/goods/edit',
    method: 'post',
    data
  })
}
// 增加库存
export function addGoodsStockAPI(data) {
  return http({
    url: '/admin/goods/stock',
    method: 'post',
    data
  })
}
// 上下架
export function changeStatusAPI(data) {
  return http({
    url: '/admin/goods/status',
    method: 'post',
    data
  })
}
// 打开/关闭推荐
export function changeRecommendAPI(data) {
  return http({
    url: '/admin/goods/recommend',
    method: 'post',
    data
  })
}
