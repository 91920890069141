import { http } from '@/http/axios.js'
// 部门树
export function getDeptTree() {
  return http({
    url: '/admin/dept/tree',
    method: 'get'
  })
}
// 岗位列表
export function getJobListAPI(params) {
  return http({
    url: '/admin/job/page',
    method: 'get',
    params
  })
}
// 角色列表
export function getRoleList(params) {
  return http({
    url: '/admin/role/page',
    method: 'GET',
    params
  })
}
// 管理员列表
export function getAdminList(params) {
  return http({
    url: `/admin/user/page`,
    method: 'get',
    params
  })
}
// 分类树
export function getGoodsCategoryTreeAPI(params) {
  return http({
    url: '/admin/goods/category/tree',
    method: 'get',
    params
  })
}
// store
export function getStoreListAPI(params) {
  return http({
    url: '/admin/store/page',
    method: 'get',
    params
  })
}
// 权益
export function getBenefitListAPI(params) {
  return http({
    url: '/admin/sys/benefit/list',
    method: 'get',
    params
  })
}